.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  border: 1px solid white;
  border-radius: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  caret-color: white;
  /* font-size: calc(10px + 1.5vmin); */
}

/* input:-internal-autofill-selected {
  background-color: red !important;
  color: blue !important;
} */

/* .profile-subcontainer {
  width: 45%;
  min-height: 85%;


} */
/* #1976d2 */
/* #0F4780 */
/* #041425 */


/* color: '#7a6800' */
.profile-container {
  width: calc(100% - 16px);
  /* height: (95% - 16px); */
  padding: 8px;
  margin: 16px;
  min-height: calc(75vh - 16px);
  display: flex;
  /* flex-direction: row; */
  /* justify-items: space-between; */


}

.flex-row {
  flex-direction: row;
  align-content: space-between;
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.subcontainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 3vh;
}

.event-container {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: row;
  min-height: calc(80vh - 16px);
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 2vh 0;
  margin: 32px;
}

.profile-subcontainer-isRow {

  width: 45%;
  /* min-height: 50vh; */
  /* height: 65vmin; */
}

.profile-subcontainer-isColumn {
  width: 100%;
  min-height: 40vh;
  margin: 1vh 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-background {
  background-color: #282c34;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}